export const IotCapabilitiesConfig = {
  api: {
    // get
    index: '/dashboard/lock-configurations/',
    // post
    update: (id = 'uuid') => {
      return `/dashboard/lock-configurations/${id}/`
    },
  },
  events: {
    name: 'iot-capabilities',
    // refresh-index-data
    refresh: `rid-iot-capabilities`,
    // slideover-right
    sorId: 'iot-capabilities',
    sorOpen: 'sor-open-iot-capabilities',
    sorClose: 'sor-close-iot-capabilities',
    sorToggle: 'sor-toggle-iot-capabilities',
    // editing-data
    editingData: 'edit-role-iot-capabilities',
    // viewing-data
    viewingData: 'view-role-iot-capabilities',
  },
}
