<template>
  <slide-over-right :id="sorId" editType="roles">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        Edit IoT Capabilities
      </h2>
    </template>

    <template v-slot:footer>
      <div class="flex items-center justify-end mt-6">
        <t-button variant="secondary" @click="dismiss">Cancel</t-button>
        <t-button type="submit" @click="submit()" :disabled="false" class="ml-3"
          >Save</t-button
        >
      </div>
      <!-- <div class="flex items-center justify-end mt-6">
        <slide-over-right-trigger :id="sorId">
          <anchor-button variant="secondary">Cancel</anchor-button>
        </slide-over-right-trigger>

      </div> -->
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="block px-6" @submit.prevent="handleSubmit(confirmSubmit)">
        <div class="flex flex-wrap w-full">
          <text-input rules="" label="Domain" v-model="item.domain" />
        </div>
        <div class="flex flex-wrap w-full">
          <text-input rules="" label="Port" v-model="item.port" />
        </div>

        <div class="grid grid-cols-1 mt-5 overflow-y-auto sb-farhan">
          <!-- start: table-container -->
          <fsTableContainer class="vld-parent">
            <loading :active.sync="isLoading" :is-full-page="false" />

            <fsTable>
              <fsTableHead>
                <fsTableHeadItem
                  v-for="(entry, index) in tableHeaders"
                  :key="`fs-table-header-${index}`"
                  :item="entry"
                  type="permission"
                  :className="entry.className"
                />
              </fsTableHead>

              <fsTableRow
                v-for="(entry, index) in capabilities"
                :key="`fs-table-row-${index}`"
              >
                <fsTableRowItem
                  type="permission"
                  className="text-left"
                  class="transform-title"
                >
                  {{ transformKeyToTitle(entry) }}
                </fsTableRowItem>
                <fsTableRowItem type="permission">
                  <t-toggle
                    :checked="item[entry] === true"
                    :disabled="false"
                    @change="onChangeToggle(index, entry)"
                    variant="success"
                  />
                </fsTableRowItem>
              </fsTableRow>
            </fsTable>
          </fsTableContainer>
          <!-- end: table-container -->
        </div>

        <input type="submit" value="Submit" ref="submitButton" class="hidden" />
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
//<t-checkbox :checked="permission.status ? true : false " class="mt-1" />
import xMan from '@/utils/xMan'
import TextInput from '@/components/form/TextInput'

import SlideOverRight from '@/components/modals/SlideOverRight'
// import SlideOverRightTrigger from "@/components/modals/SlideOverRightTrigger";

import { EventBus } from '@/utils/EventBus'
import { IotCapabilitiesConfig } from '@/config/IotCapabilitiesConfig'

import fsTableUiMixin from '@/mixins/fsTableUiMixin'

export default {
  name: 'IotCapabilitiesAddEdit',
  mixins: [fsTableUiMixin],
  components: {
    SlideOverRight,
    // SlideOverRightTrigger,
    TextInput,
  },
  data() {
    return {
      isLoading: false,
      form: {},
      capabilities: [],
      p_arr: [],
      sorId: IotCapabilitiesConfig.events.sorId,
      item: {},
      availableActionsMap: {},
      excempted_keys: [
        'id',
        'iot_category',
        'created_at',
        'updated_at',
        'domain',
        'port',
      ],
      tableHeaders: [
        {
          name: 'Capability',
          width: '35%',
          field: 'id',
          sort: null,
          order: '',
          className: 'text-left',
        },
        {
          name: 'Active',
          width: '65%',
          field: 'VIEW',
          sort: null,
          order: '',
        },
      ],
    }
  },
  // async created() {
  //   this.getPermissions()
  // },
  mounted() {
    EventBus.$on(IotCapabilitiesConfig.events.editingData, (item) => {
      this.item = item
    })
  },

  watch: {
    item: {
      deep: false,
      immediate: true,
      handler() {
        let capabs = []
        for (const key in this.item) {
          if (!this.excempted_keys.includes(key)) capabs.push(key)
        }
        this.capabilities = capabs
      },
    },
  },
  methods: {
    transformKeyToTitle(key) {
      return key
        .split('_')
        .slice(1)
        .join(' ')
    },
    dismiss() {
      console.log('dismiss')
      dispatchEvent(new Event(IotCapabilitiesConfig.events.sorToggle))
    },
    async confirmSubmit() {
      const tempItem = { ...this.item }
      this.excempted_keys.forEach((key) => {
        if (!['domain', 'port'].includes(key)) delete tempItem[key]
        if (tempItem['port'] == undefined || tempItem['port'] == null || isNaN(tempItem['port']))
          delete tempItem['port']
      })

      // console.log('temp Item', tempItem)

      var formData = new xMan(tempItem).toFormData()
      // console.log('form data')
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1])
      // }
      let url = IotCapabilitiesConfig.api.update(this.item.id)

      this.isLoading = true
      this.$http
        .patch(url, formData)
        .then((res) => {
          console.log('Response', res.data)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Updated Successfully',
              text: 'IoT Capabilities Updated',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('updateStErr--', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: 'Action failed to execute.',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
          dispatchEvent(new Event(IotCapabilitiesConfig.events.refresh))
          dispatchEvent(new Event(IotCapabilitiesConfig.events.sorClose))
        })
    },

    submit: async function() {
      this.$refs.submitButton.click()
    },

    onChangeToggle(index, capabilityType) {
      console.log('Toggle touched', index, capabilityType)
      this.item[capabilityType] = !this.item[capabilityType]
    },
  },
}
</script>

<style scoped>
.transform-title {
  text-transform: capitalize;
}
</style>
